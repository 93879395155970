// Loader.js
import React from 'react'
import loaderImage from '../../assets/images/Double Ring-0.8s-101px.gif' // Replace with the actual path to your image
import './loader.css'
const Loader = () => {
  return (
    <div className="loader-overlay">
      <img src={loaderImage} alt="Loading..." className="loader-image" />
    </div>
  )
}

export default Loader
