import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { CSVLink } from 'react-csv';


const Header = props => {
    const { subagentReportData, subagentGeneralReportData, selectedId } = props;
    const [excelData, setExcelData] = useState([]);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [generalTotalInvoice, setGeneralTotalInvoice] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [genGrandTotal, setGenGrandTotal] = useState(0);
    const [grandCommission, setGrandCommission] = useState(0);
    const [genGrandCommissionTotal, setGenGrandCommissionTotal] = useState(0);
    const [fileName, setFileName] = useState('')
    const [isAction, setIsAction] = useState(true)

    useEffect(() => {
        const epochTime = Date.now();
        const uniqueName = epochTime;
        setFileName(uniqueName + ".csv")
    }, [isAction]);
    useEffect(() => {

        const excel = []
        if (selectedId == 1) {
            subagentReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub.school_invoice_list_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "month": (index == 0) ? sub.month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "status": scl.school_invoice_status ? scl.school_invoice_status : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "subagent_name": scl.subagent_name ? scl.subagent_name : '',
                        "subagent_invoice_status": scl.subagent_invoice_status ? scl.subagent_invoice_status : '',
                        "subagent_commission": scl.subagent_commission ? scl.subagent_commission : '',
                    })
                    setGrandCommission((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setTotalInvoice((prevTotal) => prevTotal + sub.school_invoice_list_details.length)
                setGrandTotal((prevTotal) => prevTotal + sub.total_invoice_amount)
                excel.push({
                    "month": '',
                    "school_name": "Total",
                    "status": sub.school_invoice_list_details.length + " invoice",
                    "invoice_total": sub.total_invoice_amount,
                    "subagent_name": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
            subagentGeneralReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub?.general_invoice_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "month": (index == 0) ? sub.month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "status": scl.school_invoice_status ? scl.school_invoice_status : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "subagent_name": "",
                        "subagent_invoice_status": scl.subagent_status ? scl.subagent_status : '',
                        "subagent_commission": scl.subagent_commission,
                    })
                    setGenGrandCommissionTotal((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setGeneralTotalInvoice((prevTotal) => prevTotal + sub.general_invoice_details.length)
                setGenGrandTotal((prevTotal) => prevTotal + sub.total_general_invoice_amount)
                excel.push({
                    "month": '',
                    "school_name": "Total",
                    "status": sub?.general_invoice_details.length + " invoice",
                    "invoice_total": sub.total_general_invoice_amount,
                    "subagent_name": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
        }
        if (selectedId == 2 || selectedId == 3) {
            subagentReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub.subagent_list_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "school_name": (index == 0) ? (sub.billing_school_name) ? sub.billing_school_name : sub.school_name : '',
                        "month": scl.Month ? scl.Month : '',
                        "status": scl.school_invoice_status ? scl.school_invoice_status : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "subagent_name": scl.subagent_name ? scl.subagent_name : '',
                        "subagent_invoice_status": scl.subagent_invoice_status ? scl.subagent_invoice_status : '',
                        "subagent_commission": scl.subagent_commission ? scl.subagent_commission : '',
                    })
                    setGrandCommission((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setTotalInvoice((prevTotal) => prevTotal + sub.subagent_list_details.length)
                setGrandTotal((prevTotal) => prevTotal + sub.total_invoice_amount)
                let invoiceGenSubagentCommission = 0
                sub?.general_invoice_list.forEach((scl, index) => {
                    if (scl.invoice_total) {
                        invoiceGenSubagentCommission += scl.subagent_commission ? Number(scl.subagent_commission) : 0
                        excel.push({
                            "school_name": "",
                            "month": scl.Month ? scl.Month : '',
                            "status": scl.school_invoice_status ? scl.school_invoice_status : '',
                            "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                            "subagent_name": scl.subagent_name ? scl.subagent_name : '',
                            "subagent_invoice_status": scl.subagent_invoice_status ? scl.subagent_invoice_status : '',
                            "subagent_commission": scl.subagent_commission,
                        })
                        setGenGrandCommissionTotal((prevTotal) => prevTotal + Number(scl.subagent_commission))
                        setGeneralTotalInvoice((prevTotal) => prevTotal + 1)

                    }

                })
                // setGenGrandTotal((prevTotal) => prevTotal + sub.total_general_invoice_amount)
                excel.push({
                    "school_name": '',
                    "month": "Total",
                    "status": (sub.subagent_list_details.length + generalTotalInvoice) + " invoice",
                    "invoice_total": sub.total_invoice_amount,
                    "subagent_name": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": (invoiceSubagentCommission + invoiceGenSubagentCommission),
                })
            })

        }
        if (selectedId == 4) {
            subagentReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub.school_invoice_list_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "status": (index == 0) ? sub.status : '',
                        "month": scl.Month ? scl.Month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "subagent_name": scl.subagent_name ? scl.subagent_name : '',
                        "subagent_invoice_status": scl.subagent_invoice_status ? scl.subagent_invoice_status : '',
                        "subagent_commission": scl.subagent_commission ? scl.subagent_commission : '',
                    })
                    setGrandCommission((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setTotalInvoice((prevTotal) => prevTotal + sub.school_invoice_list_details.length)
                setGrandTotal((prevTotal) => prevTotal + sub.total_invoice_amount)
                excel.push({
                    "status": '',
                    "month": 'Total',
                    "school_name": sub.school_invoice_list_details.length + " invoice",
                    "invoice_total": sub.total_invoice_amount,
                    "subagent_name": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
            subagentGeneralReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub?.general_invoice_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "status": (index == 0) ? sub.status : '',
                        "month": scl.month ? scl.month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "subagent_name": "",
                        "subagent_invoice_status": scl.subagent_status ? scl.subagent_status : '',
                        "subagent_commission": scl.subagent_commission,
                    })
                    setGenGrandCommissionTotal((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setGeneralTotalInvoice((prevTotal) => prevTotal + sub.general_invoice_details.length)
                setGenGrandTotal((prevTotal) => prevTotal + sub.total_general_invoice_amount)
                excel.push({
                    "status": '',
                    "month": 'Total',
                    "school_name": sub?.general_invoice_details.length + " invoice",
                    "invoice_total": sub.total_general_invoice_amount,
                    "subagent_name": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
        }
        if (selectedId == 5) {
            subagentReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub.school_list_details.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "subagent": (index == 0) ? sub.subagent_name : '',
                        "month": scl.Month ? scl.Month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "status": scl.school_invoice_status ? scl.school_invoice_status : '',
                        "subagent_invoice_status": scl.subagent_invoice_status ? scl.subagent_invoice_status : '',
                        "subagent_commission": scl.subagent_commission ? scl.subagent_commission : '',
                    })
                    setGrandCommission((prevTotal) => prevTotal + scl.subagent_commission)
                })
                setTotalInvoice((prevTotal) => prevTotal + sub.school_list_details.length)
                setGrandTotal((prevTotal) => prevTotal + sub.total_invoice_amount)
                excel.push({
                    "subagent": '',
                    "month": 'Total',
                    "school_name": sub.school_list_details.length + " invoice",
                    "invoice_total": sub.total_invoice_amount,
                    "status": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
            subagentGeneralReportData.forEach((sub) => {
                let invoiceSubagentCommission = 0
                sub?.general_invoice_list.forEach((scl, index) => {
                    invoiceSubagentCommission += scl.subagent_commission ? scl.subagent_commission : 0
                    excel.push({
                        "subagent": (index == 0) ? sub.business_name : '',
                        "month": scl.month ? scl.month : '',
                        "school_name": scl.school_name ? scl.school_name : '',
                        "invoice_total": scl.invoice_total ? scl.invoice_total : '',
                        "status": scl.status ? scl.status : '',
                        "subagent_invoice_status": scl.subagent_status ? scl.subagent_status : '',
                        "subagent_commission": scl.subagent_commission,
                    })
                    setGenGrandCommissionTotal((prevTotal) => prevTotal + scl.subagent_commission)
                    setGenGrandTotal((prevTotal) => prevTotal + scl.invoice_total)
                })
                setGeneralTotalInvoice((prevTotal) => prevTotal + sub.general_invoice_list.length)
                excel.push({
                    "subagent": '',
                    "month": 'Total',
                    "school_name": sub?.general_invoice_list.length + " invoice",
                    "invoice_total": genGrandTotal,
                    "status": '',
                    "subagent_invoice_status": '',
                    "subagent_commission": invoiceSubagentCommission,
                })
            })
        }
        setExcelData(excel);
    }, []);

    const getCsvData = () => {
        let csvData = []
        if (selectedId == 1) {
            csvData = getInvoiceMonthReport()
        }
        if (selectedId == 2 || selectedId == 3) {
            csvData = getBillingSchoolReport()
        }
        if (selectedId == 4) {
            csvData = getInvoiceStatusReport()
        }
        if (selectedId == 5) {
            csvData = getSubagentReport()
        }

        return csvData;
    };
    const getInvoiceMonthReport = () => {
        let csvData = [[
            'Month',
            'School name',
            'Status',
            'Invoice total',
            'Subagent',
            'Subagent status',
            'Subagent commission'
        ]];
        excelData.push({
            "month": 'GRAND TOTAL',
            "school_name": "",
            "status": (totalInvoice + generalTotalInvoice) + " invoices",
            "invoice_total": (grandTotal + genGrandTotal),
            "subagent_name": '',
            "subagent_invoice_status": '',
            "subagent_commission": (grandCommission + genGrandCommissionTotal),
        })
        excelData.forEach((sub) => {
            csvData.push([
                `${sub.month}`,
                `${sub.school_name}`,
                `${sub.status}`,
                `${sub.invoice_total}`,
                `${sub.subagent_name}`,
                `${sub.subagent_invoice_status}`,
                `${sub.subagent_commission}`,
            ]);
        })
        return csvData
    }
    const getBillingSchoolReport = () => {
        const csvData = [[
            'School name',
            'Month',
            'Status',
            'Invoice total',
            'Subagent',
            'Subagent status',
            'Subagent commission'
        ]];
        excelData.push({
            "school_name": 'GRAND TOTAL',
            "month": "",
            "status": (totalInvoice + generalTotalInvoice) + " invoices",
            "invoice_total": (grandTotal + genGrandTotal),
            "subagent_name": '',
            "subagent_invoice_status": '',
            "subagent_commission": (grandCommission + genGrandCommissionTotal),
        })
        excelData.forEach((sub) => {
            csvData.push([
                `${sub.school_name}`,
                `${sub.month}`,
                `${sub.status}`,
                `${sub.invoice_total}`,
                `${sub.subagent_name}`,
                `${sub.subagent_invoice_status}`,
                `${sub.subagent_commission}`,
            ]);
        })
        return csvData
    }
    const getInvoiceStatusReport = () => {
        const csvData = [[
            'Status',
            'Month',
            'School name',
            'Invoice total',
            'Subagent',
            'Subagent status',
            'Subagent commission'
        ]];
        excelData.push({
            "status": 'GRAND TOTAL',
            "month": "",
            "school_name": (totalInvoice + generalTotalInvoice) + " invoices",
            "invoice_total": (grandTotal + genGrandTotal),
            "subagent_name": '',
            "subagent_invoice_status": '',
            "subagent_commission": (grandCommission + genGrandCommissionTotal),
        })
        excelData.forEach((sub) => {
            csvData.push([
                `${sub.status}`,
                `${sub.month}`,
                `${sub.school_name}`,
                `${sub.invoice_total}`,
                `${sub.subagent_name}`,
                `${sub.subagent_invoice_status}`,
                `${sub.subagent_commission}`,
            ]);
        })
        return csvData
    }
    const getSubagentReport = () => {
        const csvData = [[
            'Subagent',
            'Month',
            'School name',
            'Invoice total',
            'Status',
            'Subagent status',
            'Subagent commission'
        ]];
        excelData.push({
            "subagent": 'GRAND TOTAL',
            "month": "",
            "school_name": (totalInvoice + generalTotalInvoice) + " invoices",
            "invoice_total": (grandTotal + genGrandTotal),
            "status": '',
            "subagent_invoice_status": '',
            "subagent_commission": (grandCommission + genGrandCommissionTotal),
        })
        excelData.forEach((sub) => {
            csvData.push([
                `${sub.subagent}`,
                `${sub.month}`,
                `${sub.school_name}`,
                `${sub.invoice_total}`,
                `${sub.status}`,
                `${sub.subagent_invoice_status}`,
                `${sub.subagent_commission}`,
            ]);
        })
        return csvData
    }
    return (
        <div className="app-container clearfix" id="element-to-print" >
            <div>
                <div className="adminName">
                    <div className="imgContainer">
                        <CSVLink className='btn btn-primary' style={{ position: "absolute", marginTop: '10px', right: "10px" }} filename={fileName} data={getCsvData()}>
                            Export excel
                        </CSVLink>
                        <div className="borderRight" />

                    </div>
                </div>
            </div>
        </div>
    );
};

Header.defaultProps = {
    active: 0,
    isAdmin: false,
    adminName: '',
};

Header.propTypes = {
    subagentReportData: Proptypes.any, // Add this line for children prop validation
    subagentGeneralReportData: Proptypes.any,
    selectedId: Proptypes.any,
};

export default Header;