import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'

// Initialize toast configuration (you may want to do this in your main application file)

const showToast = (message, options) => {
  toast(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  })
}

export const showSuccessToast = (message) => {
  showToast(message, { theme: 'light', type: 'success' })
}

export const showErrorToast = (message) => {
  showToast(message, { theme: 'light', type: 'error' })
}
export const showErrorFix = (message) => {
  const toastId = toast(message, {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    type: 'error',
    html: true,
  });
  return toastId; // Return the toastId
}
