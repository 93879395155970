import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Reportexcel from "../../base/reportexcel/Reportexcel";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import supabase from "src/config/supabase";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "src/components/Toast";
import {
  cibSaltstack,
  cil4k,
  cilActionRedo,
  cilAlbum,
  cilBuilding,
  cilClock,
  cilLibraryBuilding,
  cilLockLocked,
  cilMobileLandscape,
  cilSatelite,
  cilUser,
} from "@coreui/icons";
import OnlineStatImg from "src/assets/images/online_stat.svg";
import Logo from "src/assets/images/EduBill_applogo.png";

import CIcon from "@coreui/icons-react";
import Loader from "src/views/common/Loader";
import Header from "../../base/reportexcel/Reportexcel";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.css";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");

  useEffect(() => {
    const hash = window.location.hash.substring("#/reset-password/".length);
    const hashArr = hash.split('&').map(e => e.split('='));
    let access_token;
    let refresh_token;
    for(let e of hashArr){
      if(e[0] === 'access_token') access_token = e[1];
      if(e[0] === 'refresh_token') refresh_token = e[1];
    }
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    console.log(access_token);
    console.log(refresh_token);
    if(!access_token || !refresh_token){
      navigate("/");
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      // oldPassword: '',
      password: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      // oldPassword: Yup.string()
      //   .required('Password is required'),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
          "Password must contain at least one capital letter, one symbol, one number, and be at least 8 characters long."
        ),
      // repeatPassword: Yup.string()
      //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
      //   .required('Repeat password is required'),
    }),
    onSubmit: async (authData, { resetForm }) => {
      try {
        setLoading(true);
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        if (data) {
          const { data, error } = await supabase.auth.updateUser({
            password: formik.values.password,
          });
          if (error) {
            showErrorToast(error.message);
          } else {
            resetForm();
            showSuccessToast("Password changed successfully");
            navigate("/");
          }
        }
        if (error) {
          showErrorToast(error.message);
        }
      } catch (e) {
        showErrorToast(e);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <>
      {loading && <Loader />}
      <div className="d-flex bg-light min-vh-100 login">
        <div className="w-100 min-vh-100 left left-reset-block">
          <div className="left-card">
            <img src={OnlineStatImg} alt="" width={500} />
            <div className="content text-center">
              <h4>Spend Less Time on Billing and More with Students</h4>
              <p>
                Streamlining commission tracking and payments for education
                agencies, schools and subagents.
                {/* Simple soltion for student management along with invoice creation and management for schools, subagents, and agents. */}
              </p>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center flex-column right-reset-main-block">
          <div className="logo d-flex align-items-center gap-3 right-reset-logo">
            <img src={Logo} width={100} alt="logo" draggable="false" />
            <span className="fs-1 fw-bolder">EduBill</span>
          </div>
          <CCard className="mb-4 w-50 right-reset-block">
            <CCardHeader  className="reset-inside-logo-block">
            <div >
              <CCol className="d-flex justify-content-center align-items-center gap-3 mb-1 ">
                <img
                  src={Logo}
                  width={100}
                  className="reset-inside-logo"
                  alt="logo"
                  draggable="false"
                />
                <span className="fs-1 fw-bolder">EduBill</span>
              </CCol>
            </div>
            </CCardHeader>
            <CCardBody>
            <h4 className="text-center mt-3 mb-5">Change password</h4>
              <CForm onSubmit={formik.handleSubmit}>
                {/* <CRow>
              <CCol>
                <CFormLabel className="fw-bolder">Old Password</CFormLabel>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CFormInput
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <small className="text-danger mt-1 w-100">{formik.errors.oldPassword}</small>
                  )}
                </CInputGroup>

              </CCol>
            </CRow> */}
                <CRow>
                  <CCol>
                    <CFormLabel className="fw-bolder">New Password</CFormLabel>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="password"
                        placeholder="New Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <small className="text-danger mt-1 w-100">
                          {formik.errors.password}
                        </small>
                      )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormLabel className="fw-bolder">
                      Repeat Password
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="repeatPassword"
                        placeholder="Repeat Password"
                        value={formik.values.repeatPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.repeatPassword &&
                        formik.errors.repeatPassword && (
                          <small className="text-danger mt-1 w-100">
                            {formik.errors.repeatPassword}
                          </small>
                        )}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className="d-flex justify-content-end">
                    <CButton color="primary" type="submit">
                      Submit{" "}
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
