import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ResetPassword from './views/pages/reset-password/ResetPassword'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Pricing = React.lazy(() => import('./views/pages/pricing/pricing'))
const Details = React.lazy(() => import('./views/pages/details/Details'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <ToastContainer />
        <Routes>
          {/* <Route></Route> */}
          <Route exact path="/pricing" name="Pricing Page" element={<Pricing />} />
          <Route exact path="/" name="Login Page" element={<Login />} />
          <Route exact path="/additional-details" name="Details Page" element={<Details />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/reset-password" name="Register Page" element={<ResetPassword />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
